import React from 'react';
import styles from './MapListingCard.module.scss';
import Heading from '@src/elements/Heading';
import classNames from 'classnames';
import { THEME_LILAC, THEME_ORANGE, THEME_BLUE } from '@src/types/themes';
import { ButtonLinkStandard } from '@src/components/ButtonLink';
import { MapListingCardType } from '@src/types/map';
import MediaImage from '@src/components/MediaImage';
import LinkWrapper from '@src/components/LinkWrapper';
import { pascalToDisplay } from '@src/utils/strings';

const MapListingCard = ({
  title,
  image,
  forSaleSlug,
  communitySlug,
  suburb,
  propertyTypes,
  theme,
  state
}: MapListingCardType) => {
  return (
    <div className={styles.mapCardContainer}>
      <div
        className={classNames(styles.mapCardTitle, {
          [styles.orangeBackground]: theme === THEME_ORANGE,
          [styles.blueBackground]: theme === THEME_BLUE,
          [styles.lilacBackground]: theme === THEME_LILAC
        })}
      >
        <Heading priority={4} fontStyle="h4">
          <LinkWrapper href={communitySlug}>{title}</LinkWrapper>
        </Heading>
      </div>
      <div className={styles.mapCardHero}>
        <LinkWrapper href={communitySlug} title={title}>
          {image && <MediaImage {...image} thumb />}
        </LinkWrapper>
      </div>
      <div className={styles.mapCardCta}>
        <p>
          {suburb} {state}
        </p>
        <Heading priority={4} fontStyle="h4">
          {pascalToDisplay(propertyTypes)}
        </Heading>
        {forSaleSlug && (
          <ButtonLinkStandard
            href={forSaleSlug}
            fillVariant="outline"
            size="lg"
          >
            See what&apos;s available
          </ButtonLinkStandard>
        )}
      </div>
    </div>
  );
};

export default MapListingCard;
