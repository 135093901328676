import React from 'react';
import styles from './StatusBadge.module.scss';
import classNames from 'classnames';

interface StatusBadge {
  bannerLabel: string;
  colour: 'red' | 'yellow';
  size?: 'default' | 'small';
}

const StatusBadge: React.FC<StatusBadge> = ({ bannerLabel, colour, size }) => {
  return (
    <div
      className={classNames(styles.statusBadgeContainer, {
        [styles.redBackground]: colour === 'red',
        [styles.yellowBackground]: colour === 'yellow',
        [styles.small]: size === 'small'
      })}
    >
      {bannerLabel}
    </div>
  );
};

export default StatusBadge;
