import Icon from '@src/elements/Icon';
import React from 'react';
import styles from './ContactCard.module.scss';
import classNames from 'classnames';
import { ButtonStandard } from '@src/components/Button';
import { CreateLeadForm } from '@src/components/Forms';
import Heading from '@src/elements/Heading';
import { GlobalSettingItemFragment } from '@src/graphql/gql-types';
import { Location } from '@src/graphql/gql-types';
import Visibility from '@src/elements/Visibility';
import IconButton from '@src/components/IconButton';
import { SVG_ENVELOPE, SVG_MAP_MARKER, SVG_PHONE } from '@src/types/svg';
import useModalStore from '@src/store';

type ContactCard = {
  name: string;
  image: string;
  position?: string | undefined;
  email?: string | undefined;
  phoneNumber?: string | undefined;
  variant?: string | undefined;
  formDisclaimerRichText?: GlobalSettingItemFragment | undefined;
  location?: Location;
  showImageOnMobile?: boolean;
};

const ContactCard = ({
  name,
  position,
  phoneNumber,
  email,
  image,
  variant,
  formDisclaimerRichText,
  location,
  showImageOnMobile = true
}: ContactCard) => {
  const emailLink = email ? `mailto:${email}` : '';
  const phoneLink = phoneNumber ? `tel:${phoneNumber}` : '';
  const { openModal } = useModalStore();

  const handleEnquireButtonClick = () => {
    const modalData = {
      heading: 'Enquire',
      content: (
        <CreateLeadForm
          disclaimerRichText={formDisclaimerRichText}
          formType="community_enquiry"
          isModal
        />
      )
    };

    openModal(modalData);
  };

  const getDirectionsURL = (lat: number, lon: number) => {
    return `https://www.google.com/maps/dir/?api=1&destination=${lat}%2C${lon}`;
  };

  return (
    <div
      className={classNames(styles.contactCardContainer, {
        [styles.propertyVariant]: variant === 'property'
      })}
    >
      <Visibility
        xs={showImageOnMobile}
        sm={showImageOnMobile}
        md
        lg
        xl
        xxl
        xxxl
      >
        <div className={styles.contactCardImage}>
          <picture>
            <source srcSet={image} type="image/webp" />
            <img src={image} alt={name} />
          </picture>
        </div>
      </Visibility>
      <div className={styles.contactCardContentWrapper}>
        {name && (
          <div className={styles.contactCardMetadata}>
            {variant === 'property' ? (
              <Heading priority={4} fontStyle="h4">
                {name}
              </Heading>
            ) : (
              <p className={styles.contactCardName}>{name}</p>
            )}
            {position && (
              <p className={styles.contactCardPosition}>{position}</p>
            )}
          </div>
        )}
        <div className={styles.contactCardCtas}>
          {phoneLink && (
            <IconButton
              href={phoneLink}
              text={phoneNumber ?? ''}
              icon={SVG_PHONE}
            />
          )}

          {emailLink && (
            <IconButton
              href={emailLink}
              icon={SVG_ENVELOPE}
              text="Send an email"
            />
          )}

          {location && location.lat && location.lon && (
            <IconButton
              icon={SVG_MAP_MARKER}
              href={getDirectionsURL(location.lat, location.lon)}
              text="Get directions"
            />
          )}

          {variant === 'property' && (
            <ButtonStandard
              color="black"
              colorVariant="light"
              fillVariant="outline"
              icon="ArrowRight"
              iconPosition="right"
              size="sm"
              aria-label="Enquire online"
              onClick={handleEnquireButtonClick}
            >
              <div className={styles.contactCardCtasLeft}>
                <div className={styles.contactCardCtasIcon}>
                  <Icon icon="At" />
                </div>
                <span>Enquire online</span>
              </div>
            </ButtonStandard>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
