import React from 'react';
import styles from './IconButton.module.scss';
import { SvgIconName } from '@src/types/svg';
import { ButtonLinkStandard } from '../ButtonLink';
import Icon from '@src/elements/Icon';

interface IconButton {
  icon: SvgIconName;
  text: string;
  href?: string;
  openNewWindow?: boolean;
  label?: string;
}
const IconButton: React.FC<IconButton> = ({
  icon,
  text,
  href,
  openNewWindow = false,
  label
}) => (
  <ButtonLinkStandard
    color="black"
    colorVariant="light"
    fillVariant="outline"
    icon="ArrowRight"
    iconPosition="right"
    size="sm"
    aria-label={label ?? ''}
    href={href}
    openNewWindow={openNewWindow}
  >
    <div className={styles.buttonContents}>
      <div className={styles.buttonIcon}>
        <Icon icon={icon} />
      </div>
      <span>{text}</span>
    </div>
  </ButtonLinkStandard>
);

export default IconButton;
