import React from 'react';
import { ContactCard } from '@src/components/Card';
import Heading from '@src/elements/Heading';
import styles from './ContactCardList.module.scss';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import classNames from 'classnames';
import { GlobalSettingItemFragment } from '@src/graphql/gql-types';
import { Location } from '@src/graphql/gql-types';
import mapPin from '@public/assets/map-pin.svg';

interface ContactCardList {
  contactPeopleCollection: Record<string, any>;
  pageType?: string;
  formDisclaimerRichText?: GlobalSettingItemFragment | undefined;
  addressHeading?: string;
  address?: string;
  location?: Location;
}
const ContactCardList: React.FC<ContactCardList> = ({
  contactPeopleCollection,
  pageType,
  formDisclaimerRichText,
  addressHeading,
  address,
  location
}) => {
  const { items } = contactPeopleCollection;

  const variant =
    pageType === CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_STANDARD
      ? 'community'
      : 'property';

  const getContactCards = (items: Record<string, any>) => {
    return items.map((card: Record<string, any>, index: number) => (
      <ContactCard
        key={index}
        name={card.name}
        position={card.position}
        phoneNumber={card.phoneNumber}
        email={card.email}
        image={card.assets?.imageSource[0]?.secure_url}
        variant={variant}
        formDisclaimerRichText={formDisclaimerRichText}
      />
    ));
  };

  return (
    <div
      className={styles.contactCardListContainer}
      data-testid="contactCardList"
    >
      <div className={styles.contactCardListTeamBlock}>
        {variant === 'community' && (
          <Heading priority={4} fontStyle="h4">
            Contact our team
          </Heading>
        )}
        <div
          className={classNames(styles.contactCardListCards, {
            [styles.propertyVariant]: variant === 'property'
          })}
        >
          {items.length > 0 && getContactCards(items)}
        </div>
      </div>

      {variant === 'community' && addressHeading && address && (
        <div className={styles.contactCardListAddressBlock}>
          <Heading priority={4} fontStyle="h4">
            {addressHeading}
          </Heading>
          <ContactCard
            key="99"
            name={address}
            image={mapPin.src}
            variant={variant}
            location={location}
            showImageOnMobile={false}
          />
        </div>
      )}
    </div>
  );
};

export default ContactCardList;
