import React from 'react';
import styles from '../NewsEventListingCard.module.scss';
import MediaImage from '@src/components/MediaImage';
import Pill from '@src/components/Pill';
import Heading from '@src/elements/Heading';
import { COLOR_PRIMARY } from '@src/types/colors';
import { SVG_CALENDAR } from '@src/types/svg';
import {
  EventListingCardCommunityFragment,
  MediaImageFragment
} from '@src/graphql/gql-types';
import Icon from '@src/elements/Icon';
import LinkWrapper from '@src/components/LinkWrapper';

type EventListingCard = Omit<
  EventListingCardCommunityFragment,
  'sys' | '__typename'
>;

const EventListingCard: React.FC<EventListingCard> = ({
  title,
  hero,
  slug,
  eventDate
}) => {
  const displayDate = new Date(eventDate).toLocaleDateString('AU', {
    dateStyle: 'medium'
  });

  return (
    <div data-testid="eventCard" className={styles.newsListingCard}>
      <LinkWrapper href={slug ?? ''}>
        <div className={styles.cardImage}>
          {hero?.mediaItem && (
            <MediaImage {...(hero.mediaItem as MediaImageFragment)} />
          )}
        </div>

        <div className={styles.listingType}>
          <Pill color={COLOR_PRIMARY} text="Event" />
        </div>

        <Heading priority={4} fontStyle="h4">
          {title}
        </Heading>

        <div className={styles.readingTime}>
          <Icon icon={SVG_CALENDAR} />
          <p> {displayDate} </p>
        </div>
      </LinkWrapper>
    </div>
  );
};

export default EventListingCard;
