import React from 'react';
import styles from '../NewsEventListingCard.module.scss';
import MediaImage from '@src/components/MediaImage';
import Pill from '@src/components/Pill';
import Heading from '@src/elements/Heading';
import Icon from '@src/elements/Icon';
import { COLOR_PRIMARY } from '@src/types/colors';
import { SVG_CLOCK } from '@src/types/svg';
import {
  MediaImageFragment,
  NewsListingCardCommunityFragment,
  NewsListingCardNationalFragment
} from '@src/graphql/gql-types';
import LinkWrapper from '@src/components/LinkWrapper';

type NewsListingCard = Omit<
  NewsListingCardNationalFragment | NewsListingCardCommunityFragment,
  '__typename' | 'sys'
>;

const NewsListingCard: React.FC<NewsListingCard> = ({
  title,
  hero,
  readingTime,
  slug
}) => {
  return (
    <div className={styles.newsListingCard} data-testid="newsCard">
      <LinkWrapper href={slug ?? ''}>
        <div className={styles.cardImage}>
          {hero?.mediaItem && (
            <MediaImage {...(hero.mediaItem as MediaImageFragment)} />
          )}
        </div>

        <div className={styles.listingType}>
          <Pill color={COLOR_PRIMARY} text="News" />
        </div>

        <Heading priority={4} fontStyle="h4">
          {title}
        </Heading>

        {!!readingTime && readingTime > 0 && (
          <div className={styles.readingTime}>
            <Icon icon={SVG_CLOCK} />
            <p>{`${readingTime} min`}</p>
          </div>
        )}
      </LinkWrapper>
    </div>
  );
};

export default NewsListingCard;
