import { Colors } from '@src/types/colors';
import { getColorClasses } from '@src/utils/colorClasses';
import classNames from 'classnames';
import React from 'react';
import styles from './Pill.module.scss';

interface Pill {
  color: Colors;
  text: string;
}

const Pill: React.FC<Pill> = ({ color, text }) => {
  const underlineStyle = classNames(
    styles.pill,
    getColorClasses(color, styles)
  );

  return <div className={underlineStyle}>{text}</div>;
};

export default Pill;
