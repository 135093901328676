import { MediaImageFragment } from '@src/graphql/gql-types';
import React from 'react';
import MediaImage from '@src/components/MediaImage';
import styles from './PropertyCard.module.scss';
import Icon from '@src/elements/Icon';
import { SVG_BATH, SVG_BED, SVG_CAR } from '@src/types/svg';
import classNames from 'classnames';
import LinkWrapper from '@src/components/LinkWrapper';
import StatusBadge from '@src/components/StatusBadge';
import { pascalToDisplay } from '@src/utils/strings';

interface PropertyCard {
  title?: string;
  image?: MediaImageFragment;
  propertyType?: string;
  minPrice?: number;
  maxPrice?: number;
  noOfBeds?: number;
  noOfBaths?: number;
  noOfCars?: number;
  statusBadgeLabel?: string;
  statusBadgeColour?: 'red' | 'yellow';
  slug?: string;
  urlPath: string;
  variant?: string;
}

const renderPricing = ({
  minPrice,
  maxPrice
}: {
  minPrice?: number;
  maxPrice?: number;
}) => {
  const [minPriceStr, maxPriceStr] = [minPrice, maxPrice]
    .filter(Boolean)
    .map((price) => '$' + price?.toLocaleString('en-US'));
  if (!minPriceStr && !maxPriceStr) {
    return '';
  }
  if (minPriceStr === maxPriceStr) {
    return `${minPriceStr}`;
  }
  if (minPriceStr && maxPriceStr) {
    return `from ${minPriceStr} to ${maxPriceStr}`;
  }
  return minPriceStr || maxPriceStr;
};

const PropertyCard: React.FC<PropertyCard> = ({
  title,
  image,
  propertyType,
  minPrice,
  maxPrice,
  noOfBeds,
  noOfBaths,
  noOfCars,
  statusBadgeLabel,
  statusBadgeColour,
  urlPath,
  variant
}) => {
  return (
    <LinkWrapper href={urlPath as string} className={styles.linkWrapper}>
      <div
        className={classNames(styles.propertyCard, {
          [styles.propertyVariant]: variant === 'property'
        })}
      >
        {statusBadgeLabel && statusBadgeColour && (
          <div className={styles.statusBadge}>
            <StatusBadge
              colour={statusBadgeColour}
              bannerLabel={statusBadgeLabel}
              size="small"
            />
          </div>
        )}

        <div className={styles.propertyImageWrapper}>
          {image && <MediaImage {...image} />}
        </div>
        <div className={styles.propertyContentWrapper}>
          <p className={styles.propertyType}>{pascalToDisplay(propertyType)}</p>
          <p className={styles.propertyTitle}>{title}</p>
          <p className={styles.propertyPriceRange}>
            {renderPricing({ minPrice, maxPrice })}
          </p>
          <div className={styles.propertyStatsWrapper}>
            {!!noOfBeds && noOfBeds > 0 && (
              <div className={styles.propertyStat}>
                <Icon icon={SVG_BED} />
                <p>{noOfBeds}</p>
              </div>
            )}
            {!!noOfBaths && noOfBaths > 0 && (
              <div className={styles.propertyStat}>
                <Icon icon={SVG_BATH} />
                <p>{noOfBaths}</p>
              </div>
            )}
            {!!noOfCars && noOfCars > 0 && (
              <div className={styles.propertyStat}>
                <Icon icon={SVG_CAR} />
                <p>{noOfCars}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </LinkWrapper>
  );
};

export default PropertyCard;
